// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-h-5-blockchain-certificate-jsx": () => import("./../../../src/pages/h5/blockchain/certificate.jsx" /* webpackChunkName: "component---src-pages-h-5-blockchain-certificate-jsx" */),
  "component---src-pages-h-5-blockchain-components-card-jsx": () => import("./../../../src/pages/h5/blockchain/components/Card.jsx" /* webpackChunkName: "component---src-pages-h-5-blockchain-components-card-jsx" */),
  "component---src-pages-h-5-blockchain-components-rotate-jsx": () => import("./../../../src/pages/h5/blockchain/components/Rotate.jsx" /* webpackChunkName: "component---src-pages-h-5-blockchain-components-rotate-jsx" */),
  "component---src-pages-h-5-blockchain-components-step-jsx": () => import("./../../../src/pages/h5/blockchain/components/Step.jsx" /* webpackChunkName: "component---src-pages-h-5-blockchain-components-step-jsx" */),
  "component---src-pages-h-5-blockchain-index-jsx": () => import("./../../../src/pages/h5/blockchain/index.jsx" /* webpackChunkName: "component---src-pages-h-5-blockchain-index-jsx" */),
  "component---src-pages-h-5-blockchain-ngtc-jsx": () => import("./../../../src/pages/h5/blockchain/ngtc.jsx" /* webpackChunkName: "component---src-pages-h-5-blockchain-ngtc-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-privacyapp-jsx": () => import("./../../../src/pages/privacyapp.jsx" /* webpackChunkName: "component---src-pages-privacyapp-jsx" */),
  "component---src-pages-privacycz-jsx": () => import("./../../../src/pages/privacycz.jsx" /* webpackChunkName: "component---src-pages-privacycz-jsx" */),
  "component---src-pages-process-jsx": () => import("./../../../src/pages/process.jsx" /* webpackChunkName: "component---src-pages-process-jsx" */),
  "component---src-pages-service-jsx": () => import("./../../../src/pages/service.jsx" /* webpackChunkName: "component---src-pages-service-jsx" */),
  "component---src-pages-servicecz-jsx": () => import("./../../../src/pages/servicecz.jsx" /* webpackChunkName: "component---src-pages-servicecz-jsx" */)
}

